import React from "react";
import { Link } from "react-router-dom";
import about from '../../images/about.jpg'

function About() {
  return (
    <div className="container my-5">
      <div className="d-block d-lg-flex">
        <div className="pe-0 pe-lg-3">
          <h4>ABOUT</h4>
          <p className="h1 text-warning text-nowrap">SOLAR IMPLEMENTS</p>
          <p className="h1 text-warning text-nowrap">for GENERAL TRADING </p>
          <div className="w-10 text-center pt-4">
          <img className="w-75 object-fit-cover"
            src={about}
            alt="generalTwo"
            loading="lazy" />
          </div>
        </div>

        <div className="vl"></div>

        <div className="px-lg-5 w-100">
          <p className=" lh-lg fs-5 fw-light">
            Welcome to{" "}
            <span className="text-warning fw-bold">SOLAR IMPLEMENTS COMPANY</span>
            , where passion meets quality in the world of general trade. Established with a commitment to excellence, we take pride in being a leading provider of premium meats, chicken, and farm-fresh eggs. Our journey began with a simple belief – to provide families with the finest culinary experiences through top-notch products.
          </p>
          <p className="text-justify lh-lg fs-5 fw-light">
          At{" "}
            <Link className="link-warning" to="https://solar-imp.com">
              {" "}
              SOLAR IMPLEMENTS COMPANY
            </Link>
            , we are more than just a general trade company; we are culinary enthusiasts dedicated to elevating your kitchen adventures. With a focus on quality, transparency, and customer satisfaction, we have become a trusted name in the industry.
          </p>
          {/* <Link
            to="/aboutus"
            className="btn btn-warning rounded-1 fs-5 fw-bold py-2"
          >
            About Us
          </Link> */}
        </div>
      </div>
    </div>
  );
}

export default About;

import React from "react";

function Location() {
  return (
    <div class="w-100 mt-2">
      <h3 class="text-center mb-4">OUR LOCATION</h3>
      <iframe
      className="w-100 map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1898.3827722986432!2d42.863164170043966!3d36.84998268472516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2siq!4v1705438172929!5m2!1sen!2siq"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        title="Solar Implements"
      ></iframe>
    </div>
  );
}

export default Location;

import React from "react";
import About from "../components/home/About";
import Banner from "../components/home/Banner";
import Services from "../components/home/Services";
import Projects from "../components/home/Projects";
import Location from "../components/home/Location";
import MissionAndVision from "../components/home/MissionAndVision";

function Home() {
  return (
    <div>
      <Banner />
      <About />
      <div class="container" style={{ color: "green" }}>
        <hr />
      </div>
      <MissionAndVision />
      {/* <Services /> */}

      <Location />
    </div>
  );
}

export default Home;

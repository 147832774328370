import imageTwo from "../images/general-two.jpg";
import imageThree from "../images/general-three.jpg";
import imageFour from "../images/general-four.jpg";

export default function ServicesPage() {
  return (
    <div>
      <div style={{ height: "70px" }}></div>

      <div className="container">
        <div className="w-100 py-5 my-5 text-center text-warning">
          <h1>Coming Soon</h1>
        </div>

        {/* <div className="d-lg-flex d-block p-2">
          <div className="py-lg-5 py-2">
            <h2 className="h1 text-nowrap">
              OUR <span className="text-success">SERVICES</span>
            </h2>
          </div>
          <div className="vl mx-5 bg-success d-lg-block d-none"></div>
          <p className="w-100 py-2 fs-4">
            At KATO TRADING, we offer a diverse range of services tailored to
            meet the needs of our clients.
          </p>
        </div>
        <hr style={{ height: "2px" }} />

        <h2 className="text-center my-5">Main Services </h2>

        <div className="mx-md-4 mx-2">
          <div className="d-flex flex-lg-row flex-column bg-body-secondary my-4">
            <div className="w-100 overflow-hidden ">
              <img
                className="w-100 h-100 object-fit-cover"
                src={imageTwo}
                alt="img"
              />
            </div>
            <div className="w-100 p-md-5 p-2">
              <h3 className="mb-md-4 mt-md-0 my-3">
                Building & Construction Materials
              </h3>
              <div className="fs-5 fw-light">
                <p>
                  From high-quality raw materials to finished products, we
                  provide a comprehensive range of building and construction
                  materials to support projects of any scale.
                </p>
                <p>
                  Our commitment to quality ensures that your construction
                  endeavors are built on a strong foundation.
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex flex-lg-row flex-column bg-body-secondary my-4">
            <div className="w-100 overflow-hidden ">
              <img
                className="w-100 h-100 object-fit-cover"
                src={imageFour}
                alt="img"
              />
            </div>
            <div className="w-100 p-md-5 p-2">
              <h3 className="mb-md-4 mt-md-0 my-3">Solar Energy Equipment</h3>
              <div className="fs-5 fw-light">
                <p>
                  Embrace sustainability with our state-of-the-art solar energy
                  solutions.
                </p>
                <p>
                  We offer a range of solar equipment, from solar panels to
                  inverters, designed to harness the power of the sun and
                  contribute to a greener future.
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex flex-lg-row flex-column bg-body-secondary my-4">
            <div className="w-100 overflow-hidden ">
              <img
                className="w-100 h-100 object-fit-cover"
                src={imageThree}
                alt="img"
              />
            </div>
            <div className="w-100 p-md-5 p-2">
              <h3 className="mb-md-4 mt-md-0 my-3">E-commerce Services</h3>
              <div className="fs-5 fw-light">
                <p>
                  Simplify your procurement process with our e-commerce
                  services.
                </p>
                <p>
                  Explore a user-friendly platform that streamlines the buying
                  process for construction materials and solar energy equipment.
                </p>
                <p>
                  Our digital solutions are designed to save you time, enhance
                  efficiency, and provide a seamless online experience.
                </p>
              </div>
            </div>
          </div>
        </div>

        <p className="text-center fs-4 my-5 mx-2">
          Choose
          <span className="text-success fw-bold"> KATO TRADING </span>
          as your trusted partner, and let's build a future of success together.
        </p> */}
      </div>
    </div>
  );
}

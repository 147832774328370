export default function ErrorPage() {
  return (
    <div
      style={{
        height: "50dvh",
      }}
      className="d-flex flex-column"
    >
      <div style={{ height: "70px" }}></div>
      <div className="h-100 d-flex flex-column align-items-center justify-content-center">
        <h2 className="h-1"> 404 </h2>
        <p className="fs-4">PAGE DOES NOT EXIST</p>
      </div>
    </div>
  );
}

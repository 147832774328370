import React from "react";
import generalOne from "../images/general-one.jpg";

export default function AboutUs() {
  return (
    <div className="my-">
      <div style={{ height: "70px" }}></div>
      <div className="container">
        <div className="d-lg-flex d-block p-2">
          <div className="py-lg-5 py-2">
            <h3 className="">About</h3>
            <h2 className="h1 text-warning text-nowrap">KATO TRADE</h2>
          </div>

          <div className="vl mx-5 bg-warning d-lg-block d-none"></div>
          <p className="w-100 py-2 fs-4">
            Welcome to KATO TRADING L.L.C-FZ, your trusted partner in global
            trade and commerce. Established in the vibrant business hub of
            Dubai, UAE, KATO TRADING L.L.C-FZ is a dynamic and forward-thinking
            company committed to delivering unparalleled services and fostering
            lasting partnerships.
          </p>
        </div>

        <div className="bg-body-secondary border-top">
          <ImageTextContainer
            image={generalOne}
            text={
              "As a proud subsidiary of KATO for General Contracts, based in the Kurdistan Region of Iraq, our roots trace back to 2001. With over two decades of invaluable experience in the field of Construction Contracting, KATO for General Contracts has garnered a reputation for excellence, reliability, and innovation. It is this rich heritage that forms the foundation of KATO TRADING L.L.C-FZ, providing us with a solid platform to excel in the world of international trade."
            }
            ltr={true}
          />

          <ImageTextContainer
            image={generalOne}
            text={
              "At KATO TRADING L.L.C-FZ, we specialize in facilitating seamless global transactions, leveraging our extensive network and market insights. Our team of dedicated professionals is committed to understanding and meeting the unique needs of our clients, ensuring the success of every venture we undertake."
            }
          />

          <ImageTextContainer
            image={generalOne}
            text={
              "As a company deeply rooted in the values of integrity, professionalism, and customer satisfaction, we pride ourselves on delivering top-tier services across a spectrum of industries. From procurement and logistics to distribution and beyond, KATO TRADING L.L.C-FZ is your one-stop solution for all your trading needs."
            }
            ltr={true}
          />
          <ImageTextContainer
            image={generalOne}
            text={`We invite you to explore our website and discover the diverse range of services we offer. Whether you are a seasoned player in the international market or a budding entrepreneur, KATO TRADING LLC FZ is here to support and elevate your business to new heights.`}
          />

          <div className="d-flex align-items-center justify-content-center">
            <div
              className="d-flex flex-column mt-lg-5 py-lg-5 mt-4 py-0 position-relative px-4 py-2"
              style={{ maxWidth: "800px" }}
            >
              <p className="fs-4">
                Thank you for considering KATO TRADING L.L.C-FZ as your
                preferred partner in trade. We look forward to building lasting
                relationships and contributing to the success of your business
                endeavors.
              </p>
              <div className="align-self-end">
                <p className="mb-0 fst-italic">Sincerely,</p>
                <p className="mb-0 fst-italic">KATO TRADING L.L.C-FZ</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ImageTextContainer({ text, image, ltr = false }) {
  const TextSect = () => <p className="fs-5 mb-0 w-100 px-4 py-5">{text}</p>;
  const ImgSect = () => (
    <div className="w-100 overflow-hidden custom-aboutus-image-container">
      <img className="w-100 h-100 object-fit-cover" src={image} alt="img" />
    </div>
  );

  return (
    <div className="d-flex flex-lg-row flex-column gap-lg-4 gap-0 mb-4">
      {ltr ? (
        <React.Fragment>
          <ImgSect />
          <TextSect />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TextSect />
          <ImgSect />
        </React.Fragment>
      )}
    </div>
  );
}

import React, { useState } from "react";
import logo from "../../images/logo.png";
import "./header.css";

function Header() {
  const [isScroll, setScroll] = useState(false);
  const chaneScroll = () => {
    if (window.scrollY > 20) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener("scroll", chaneScroll);
  return (
    <React.Fragment>
      <nav
        className={
          isScroll
            ? "navbar navbar-expand-lg fixed-top navbar-light border-bottom px-2 navbar-trans-active"
            : "navbar navbar-expand-lg fixed-top navbar-light border-bottom px-2 navbar-trans"
        }
        style={{ maxHeight: "none" }}
      >
        <div className="container-fluid">
          <a className="navbar-brand d-block " href="/">
            <img
              src={logo}
              alt="new logo"
              ezimgfmt="rs rscb1 src ng ngcb1"
              style={{ maxHeight: "50px" }}
              data-ezsrc={logo}
              ezoid="0.18851206252709396"
            />
          </a>

          <h6 className="mb-0 d-lg-block d-none text-warning">
            SOLAR IMPLEMENTS
          </h6>
          <h3 className="mb-0 d-lg-none d-block text-warning">
            SOLAR IMPLEMENTS
          </h3>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse mx-3" id="navbarNavDropdown">
            <ul className="navbar-nav ">
              <li className="nav-item">
                <a
                  className="nav-link mx-2 fw-bold text-uppercase"
                  aria-current="page"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link mx-2 fw-bold text-uppercase"
                  href="/services"
                >
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link mx-2 fw-bold text-uppercase"
                  href="/contactus"
                >
                  Contact Us
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link mx-2 fw-bold text-uppercase"
                  href="aboutus"
                >
                  About Us
                </a>
              </li> */}
            </ul>
            <ul className="navbar-nav ms-auto d-none d-lg-inline-flex">
              <li className="nav-item">
                <a
                  href="tel:+964 750 457 6730"
                  className="btn btn-outline-warning my-2 my-sm-0 bg-warning px-3 text-dark"
                  type="submit"
                >
                  Call Phone
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}

export default Header;

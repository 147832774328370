import React from "react";
import banner from "../../images/banner.png";
import "./home.css";

function Banner() {
  return (
    <div>
      <img id="banner-image" class="img-fluid" src={banner} loading="lazy" />
    </div>
  );
}

export default Banner;

import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="bg-dark text-white pb-5">
      <div className="d-lg-flex d-block gap-4 container px-3 py-5 ">
        <div className="w-100">
          <h3 className="mb-4">COMPANY</h3>
          <p className="text-white-50">
            Our team, comprised of experts in the field, is driven by a shared
            passion for delivering excellence. From sourcing the best meats to
            curating a selection of kitchen essentials, every product in our
            inventory reflects our commitment to providing nothing but the best.
          </p>
          <div className="mt-4"></div>
        </div>
        <div className="w-100 mt-lg-0 mt-5">
          <h3 className="mb-4">CONTACT</h3>
          <div className="d-sm-flex d-none">
            <div className="w-25">
              <p className="fw-semibold">Telephone</p>
              <p className="fw-semibold">Email</p>
              <p className="fw-semibold">Address </p>
            </div>
            <div className="w-100">
              <p>+964 750 457 6730 or +964 0751 441 4038</p>
              <p>solar.co7979@gmail.com</p>
              <p>Behind Sumail Land Registry Directorate</p>
            </div>
          </div>
          <div className="d-sm-none d-block">
            <div>
              <p className="mb-0 fw-semibold">Telephone</p>
              <p>+964 750 457 6730 or +964 0751 441 4038</p>

              <p className="mb-0 fw-semibold">Email</p>
              <p>solar.co7979@gmail.com</p>

              <p className="mb-0 fw-semibold">Address</p>
              <p className="ms-2">Behind Sumail Land Registry Directorate</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-md-flex d-block gap-4 ">
        <div className="w-100 mt-md-0 mt-3 d-flex justify-content-center">
          <p className="text-white-50 mb-0 ">@ 2024 MubaTech</p>
        </div>
      </div>
    </div>
  );
}

import generalOne from "../../images/general-one.jpg";
import generalTwo from "../../images/general-two.jpg";
import generalThree from "../../images/general-three.jpg";
import generalFour from "../../images/general-four.jpg";

export default function MissionAndVision() {
  return (
    <div className="container">
      <div className="d-flex bg-body-tertiary flex-lg-row flex-column mx-lg-5 mx-0 my-4">
        <div className="w-100 px-md-5 px-4 py-4">
          <h2 className="text-uppercase">Vision</h2>
          <p className="fs-5 text-light-emphasis">
            Our vision is to be the premier destination for discerning
            individuals seeking top-quality meats, flavorful chicken, and fresh
            eggs. We envision a future where every kitchen is a haven of
            gastronomic delight, and our products play a pivotal role in
            creating memorable meals. We aim to set industry standards by
            consistently delivering excellence, sustainability, and satisfaction
            to our valued customers.
          </p>
        </div>
        <div className="w-100 overflow-hidden">
          <img
            className="w-100 h-100 object-fit-cover"
            src={generalTwo}
            alt="generalTwo"
            loading="lazy"
          />
        </div>
      </div>

      <div className="d-flex bg-body-tertiary flex-lg-row flex-column mx-lg-5 mx-0 my-4">
        <div className="w-100 overflow-hidden">
          <img
            className="w-100 h-100 object-fit-cover"
            src={generalOne}
            alt="generalOne"
            loading="lazy"
          />
        </div>
        <div className="w-100 px-md-5 px-4 py-4">
          <h2 className="text-uppercase">Mission</h2>
          <p className="fs-5 text-light-emphasis">
            Our mission is to provide households and businesses with top-tier
            meats, succulent chicken, and farm-fresh eggs that redefine culinary
            excellence. We are committed to sourcing the best products, ensuring
            transparency in our processes, and delivering unparalleled freshness
            to your doorstep. Our mission is grounded in the belief that
            everyone deserves access to premium quality ingredients for their
            everyday meals.
          </p>
        </div>
      </div>
    </div>
  );
}

import Location from "../components/home/Location";

export default function ContactUs() {
  return (
    <div>
      <div style={{ height: "70px" }}> </div>
      <div className="container">
        <div className="d-lg-flex d-block p-2">
          <h2 className="h1 text-nowrap py-lg-5 py-2">CONTACT US</h2>
          <div className="vl mx-5 bg-warning d-lg-block d-none"></div>
          <div className="w-100 py-2 fs-4">
            <p>
              At <strong className="text-warning">SOLAR IMPLEMENTS</strong>, we understand that the kitchen is the heart of the home, and our mission is to bring joy and satisfaction to every culinary creation. With a team of experts who share a deep love for food, we have curated a selection of products that exemplify our dedication to excellence.
            </p>
            <p>
            Explore the world of flavors with <strong className="text-warning">SOLAR IMPLEMENTS</strong> – your trusted partner in delivering premium meats, juicy chicken, and wholesome eggs for a culinary experience like no other.
            </p>
            <p>
              Explore the possibilities with
              <strong className="text-warning">SOLAR IMPLEMENTS </strong> and let's
              build the future together.
            </p>
          </div>
        </div>
        <hr className="bg-warning" />
        <div className="d-lg-flex d-block ">
          <div className="my-2 mb-4 fs-5">
            <h3 className="mb-4"> CONTACT </h3>
            <p>
              <strong>Email</strong> <br /> solar.co7979@gmail.com
            </p>
            <p>
              <strong>Telephone</strong> <br />+964 750 457 6730 or +964 0751 441 4038
            </p>
            <p>
              <strong>Address</strong> <br /> Behind Sumail Land Register Directorate
            </p>
          </div>
          <Location />
        </div>
      </div>
    </div>
  );
}
